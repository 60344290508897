import messages from '@/tools/messages'

//---------------------------------------------------------------------------------------------------
function lpu(lpuid) {
    console.log('lpuid--!--',lpuid) //, _this.$route.path,_this.$route.fullPath,_this.$route)
    let arr= [
        {
            npp: "1",
            visible: true,
            name: 'ООО &nbsp;&laquo;Стоматологическая поликлиника №4&raquo;',
            address: '454119, г.Челябинск, ул.Дзержинского, 10',
            tel: '+7(351) 253-55-83',
            telto: null,
            mailto: null,
            email: 'system-sp4@mail.ru',
            routes: ['/','/fin','/age','/medg','/med','/date','/time','/kard','/sta'],
            //resturl: 'http://78.29.8.156:9003',
            resturl: 'https://infokiosk2.talon74.ru',
            // старый resturl: 'https://stom4.talon74.ru:9009',
            //resturl: 'https://stom4.talon74.ru:80',
            nppfilial: '1',
            lpuid: 'st4',
            preset: []
        },
        {
            npp: "2",
            visible: true,
            name: 'Детская поликлиника ОКБ №2',
            address: '454010, г. Челябинск, ул. Гагарина, 18, кабинет 214',
            tel: '+7(351) 253-55-83',
            telto: null,
            mailto: null,
            email: 'system-sp4@mail.ru',
            routes: ['/', '/med', '/date', '/time', '/kard', '/sta'],
            //resturl: 'http://78.29.8.156:9003',
            // старый resturl: 'https://stom4.talon74.ru:9009',
            resturl: 'https://infokiosk2.talon74.ru',
////            resturl: 'https://stom4.talon74.ru:9009',
            //resturl: 'https://stom4.talon74.ru:80',
            nppfilial: '2',
            lpuid: 'st42',
            preset: [
                {
                    set_store: 'SET_FIN',
                    arr:  {
                        id: 'Oms',
                        name: 'Прием ОМС',
                        opt: 'VT'
                    }
                },
                {
                    set_store: 'SET_AGE',
                    arr:  {
                        id: 'Det',
                        name: 'Детский прием'
                    }
                },
                {
                    set_store: 'SET_MEDG',
                    arr:  {
                        DET: '1',
                        PANELCAPTION1: 'Терапевтический прием',
                        PANELCAPTION2: '(Лечение)',
                        PANELNAME: 'PanelOmsDetTer',
                        PAY: 0,
                        SSORT: '11'
                    }
                }

            ]
        },

        {
            npp: "5",
            visible: true,
            name: 'ООО &nbsp;&laquo;Медосмотры&raquo;',
            address: '454084, г. Челябинск, ул. Братьев Кашириных, д.34',
            tel: '+7(351) 211-47-77',
            telto: null,
            mailto: null,
            email: 'medocmotr@gippokrat74.ru',
            routes: ['/', '/med', '/date', '/time', '/kard', '/sta'],
            //resturl: 'http://78.29.8.156:9003',
            // старый resturl: 'https://stom4.talon74.ru:9009',
            resturl: 'https://infokiosk2.talon74.ru',
////            resturl: 'https://stom4.talon74.ru:9009',
            //resturl: 'https://stom4.talon74.ru:80',
            nppfilial: '2',
            lpuid: 'gipp',
            preset: [
                {
                    set_store: 'SET_FIN',
                    arr:  {
                        id: 'Oms',
                        name: 'Кабинет УЗИ',
                        opt: 'VT'
                    }
                },
                {
                    set_store: 'SET_AGE',
                    arr:  {
                        id: 'Det',
                        name: 'Предварительная запись на прием'
                    }
                },
                {
                    set_store: 'SET_MEDG',
                    arr:  {
                        DET: '1',
                        PANELCAPTION1: '',
                        PANELCAPTION2: '',
                        PANELNAME: 'PanelOmsDetTer',
                        PAY: 0,
                        SSORT: '11'
                    }
                }

            ]
        },



        /*,
        {
            npp: "2",
            visible: true,
            name: 'МАУЗ&nbsp;&laquo;Детская Городская Поликлиника №6&raquo;',
            address: '454139, г.Челябинск, ул. Новороссийская, 65',
            tel: '+7(351) 253-55-83',
            telto: null,
            mailto: null,
            email: 'system-sp4@mail.ru',
            routes: ['/', '/med', '/date', '/time', '/kard', '/sta'],
            //resturl: 'http://78.29.8.156:9003',
            // старый resturl: 'https://stom4.talon74.ru:9009',
            resturl: 'https://infokiosk2.talon74.ru',
////            resturl: 'https://stom4.talon74.ru:9009',
            //resturl: 'https://stom4.talon74.ru:80',
            nppfilial: '2',
            lpuid: 'st42',
            preset: [
                {
                    set_store: 'SET_FIN',
                    arr:  {
                        id: 'Oms',
                        name: 'Прием ОМС',
                        opt: 'VT'
                    }
                },
                {
                    set_store: 'SET_AGE',
                    arr:  {
                        id: 'Det',
                        name: 'Детский прием'
                    }
                },
                {
                    set_store: 'SET_MEDG',
                    arr:  {
                        DET: '1',
                        PANELCAPTION1: 'Терапевтический прием',
                        PANELCAPTION2: '(Лечение)',
                        PANELNAME: 'PanelOmsDetTer',
                        PAY: 0,
                        SSORT: '11'
                    }
                }

            ]
        }
        */
    ];

    if(process.env.NODE_ENV==='development') {
        arr.push({
            npp: "3",
            visible: true,
            name: 'ООО &nbsp;&laquo;Стоматологическая поликлиника №4&raquo; Локально',
            address: '454119, г.Челябинск, ул.Дзержинского, 10',
            tel: '+7(351) 253-55-83',
            telto: null,
            mailto: null,
            email: 'sp-admin111@mail.ru',
            routes: ['/', '/fin', '/age', '/medg', '/med', '/date', '/time', '/kard', '/sta'],
            //resturl: 'https://stom4.talon74.ru:9009',
// старый            resturl: 'https://stom4.talon74.ru:9009',
            resturl: 'https://infokiosk2.talon74.ru',
            nppfilial: '1',
            lpuid: 'st4loc',
            preset: []
        });
        arr.push({
            npp: "4",
            visible: true,
            name: 'МАУЗ&nbsp;&laquo;Детская Городская Поликлиника №6&raquo;  Локально',
            address: '454139, г.Челябинск, ул. Новороссийская, 65',
            tel: '+7(351) 253-55-83',
            telto: null,
            mailto: null,
            email: 'sp-admin111@mail.ru',
            routes: ['/', '/med', '/date', '/time', '/kard', '/sta'],
            resturl: 'http://localhost:9003',
            //resturl: 'https://stom4.talon74.ru:9009',
            nppfilial: '2',
            lpuid: 'st42loc',
            preset: [
                {
                    set_store: 'SET_FIN',
                    arr:  {
                        id: 'Oms',
                        name: 'Прием ОМС',
                        opt: 'VT'
                    }
                },
                {
                    set_store: 'SET_AGE',
                    arr:  {
                        id: 'Det',
                        name: 'Детский прием'
                    }
                },
                {
                    set_store: 'SET_MEDG',
                    arr:  {
                        DET: '1',
                        PANELCAPTION1: 'Терапевтический прием',
                        PANELCAPTION2: '(Лечение)',
                        PANELNAME: 'PanelOmsDetTer',
                        PAY: 0,
                        SSORT: '11'
                    }
                }

            ]
        });
    }

    //console.log('lpuid----',arr,lpuid)
    if(lpuid && typeof lpuid!='undefined'){ //возвратить только одно ЛПУ
        //console.log('lpuid<<<<',lpuid)
        let index = arr.findIndex(e => e.lpuid == lpuid)
        //console.log('lpuid',lpuid,index,arr)
        if (index != -1) {
            arr=arr.slice(index,index+1)
            //console.log('slice',index,arr)
        }
    }

    return arr
}

//---------------------------------------------------------------------------------------------------
// '/med/:lpu' => '/med'
// '/:lpuid' => '/'
function pathKernel(route){
    let ret='/'
    let arr=route.split('/')
    if(arr.length>1){
        ret=ret+arr[1] //"/med" -> ["","med",""]
    }
    return ret
}
//---------------------------------------------------------------------------------------------------
async function  toBack(p_this){
    let to='/'
    try {
        if(p_this.drawer) {
           p_this.drawer = false
        }


        let index
        const LPU=p_this.$store.getters.LPU
        const path = pathKernel(p_this.$route.path)
        if (LPU && LPU.routes) {
            index = LPU.routes.indexOf(path)
            if (index > 0) {
                to=LPU.routes[index - 1]
            }
        }
    }catch(err){
        console.log('toBack() err:',err)
        to='/'
    }
    await p_this.$router.push(to)

}
//---------------------------------------------------------------------------------------------------
async function  toNext(p_this){
    let to='/'

    try {
        if(p_this.drawer) {
            p_this.drawer = false
        }

        let index
        const LPU = p_this.$store.getters.LPU
        let path = pathKernel(p_this.$route.path)
        if(path=='/lpu'){
            path='/'
        }


        if (LPU && LPU.routes) {
            index = LPU.routes.indexOf(path)
            if (index >= 0 && index < LPU.routes.length - 1) {
                to=LPU.routes[index + 1]
            }
        }
    }catch(err){
        console.log('toNext() err:',err)
    }
    await p_this.$router.push(to)
}
//---------------------------------------------------------------------------------------------------
function cvtErr(p_err){
    let text=''
    if(messages[p_err]){
        text=messages[p_err]
    }else{
        text=p_err
        console.log('--------------- Добавить в messages[]:',p_err)
    }
    return text
}

//---------------------------------------------------------------------------------------------------
function thisLog(p_this,arr,options) {
    if(process.env.NODE_ENV==='development') {
        if (p_this && p_this.log) {
            let pre = ''
            do {
                if (!options) {
                    pre = 'log no-options:'
                    break;
                }
                if (options.dev) {
                    pre = 'log option-dev:'
                    break;
                }
                if (options.prod) {
                    pre = 'log option-prod:'
                    break;
                }
            } while (false)
            arr = [pre, ...arr]
            //console.log(...arr)
        }
    }
}

//---------------------------------------------------------------------------------------------------
function curstaEmpty(){
    return  {
        DDATE:"",
        INFO:"",
        MED:"",
        NPPKARD:"",
        NPPSTA:"",
        NU:"",
        TTIME:""
    }
}




export {lpu, toBack, toNext, thisLog, curstaEmpty} // список экспортируемых переменных